/**
 * Direttiva che costruisce la lista del banner
 */

export interface IBannerListDirectiveScope extends ng.IScope {
	goToCourseDetail: Function;
	chartOptions: any;
	card: any;
	windowWidth: number;
	isWidthExceeded: boolean;
	itemType: any;
	hourValue: any;
	isItemOpen: boolean;
	isLearningPlan: boolean;
	isTechSkill: boolean;
	isSoftSkill: boolean;
	percentageItem: any;
	suggestedPerson: any;
	suggestedFromAdmin: any;
	hasSuggested: any;
	globalApplicationData: any;
	bgacademyApplicationData: any;
	item: any;
	forceToggleIndicator: boolean;
	defaultOnSuggestion: boolean;
	iconToShow: string;
	iconToShowAlt: string;
}
angular.module('app').directive("bannerList", ($window, $state, BgacademyApplicationData, GlobalApplicationData) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			item: '=', // Oggetto interno contenente tutte le informazioni della riga che sto andando a costruire
			selectedItemList: "=", // bind sull'array che conterrà le righe selezionate
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			page: '@',
			isLearningPlanEditingMode: '@',
			openItemDetail: '&',
			suggestedPersonExists: '&',
			toggleSuggestedPerson: '&',
			itemChildsExists: '&',
			toggleItemChilds: '&'
		},
		link: link,
		templateUrl: 'app/shared/bannerList/bannerList.html'
	};
	function link($scope: IBannerListDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Item passato alla Direttiva. Se esiste il referenceId significa che si tratta 
		// di un childItem
		$scope.card = $scope.item;

		// Verifica se come prima cosa devo mostrare la persona (non ho altri dati disponibili)
		$scope.defaultOnSuggestion = true;

		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		// Configurazione della percentuale
		$scope.chartOptions = {
			animate: {
				duration: 800,
				enabled: true
			},
			barColor: '#1abc9c',
			scaleColor: '',
			trackColor: '#fff',
			lineWidth: 2,
			lineCap: 'butt',
			size: 35
		}

		// Tipo dell'oggetto (video, audio, e-book...)
		$scope.itemType = null;

		// Valore formativo
		$scope.hourValue = null;

		// Oggetto aperto
		$scope.isItemOpen = false;

		// Verifica se è una competenza tecnica
		$scope.isTechSkill = false;

		// Verifica se è una competenza soft
		$scope.isSoftSkill = false;

		// Oggetto iniziato
		$scope.percentageItem = false;

		// Persona che ha suggerito l'elemento
		$scope.suggestedPerson = null;

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Verifica se è un learning Plan
		$scope.isLearningPlan = false;

		// Verifica se ha suggeritori
		$scope.hasSuggested = false;

		// Collegamento all'oggetto globale
		$scope.globalApplicationData = GlobalApplicationData;

		$scope.windowWidth = $window.innerWidth;
		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if ($scope.card && $scope.card.title.length < 62) {
			$scope.card.titleClass = 'card-title-h3';
		} else if ($scope.card && $scope.card.title.length > 62 && $scope.card.title.length > 62) {
			$scope.card.titleClass = 'card-title-h2';
		}

		// Eseguo la verifica sul carattere anche al resize e al caricamento della pagina
		angular.element($window).bind('resize', () => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if ($scope.windowWidth < 600 && $scope.card && $scope.card.title.length < 62) {
				$scope.card.titleClass = 'card-title-h6';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h3';
			}

			if ($scope.windowWidth < 600 && $scope.card.title.length > 62 && $scope.card.title.length > 62) {
				$scope.card.titleClass = 'card-title-h5';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h2';
			}
		});
		// e devo controllare al caricamento della pagina
		angular.element(document).ready(() => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if ($scope.windowWidth < 600 && $scope.card && $scope.card.title.length < 62) {
				$scope.card.titleClass = 'card-title-h6';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h3';
			}

			if ($scope.windowWidth < 600 && $scope.card.title.length > 62 && $scope.card.title.length > 62) {
				$scope.card.titleClass = 'card-title-h5';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h2';
			}
		});


		// Verifico gli attributi dell'oggetto
		if ($scope.item) {
			if ($scope.item.itemAttributes) {
				let itemAttributes = $scope.item.itemAttributes;
				for (let k = 0; k < itemAttributes.length; k++) {
					// Verifico se è una competenza soft
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_SKILL) {
						$scope.isSoftSkill = true;
					}
					// Verifico se è una competenza tecnica
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.TECHNICAL_SKILL) {
						$scope.isTechSkill = true;
					}

					// Verifico il tipo di oggetto
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.OBJECT_TYPE) {
						$scope.itemType = itemAttributes[k].attributeValue;
					}

					// Verifico se è un Learning Plan
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.LEARNING_PLAN) {
						$scope.isLearningPlan = true;
					}

					// Verifico se c'è il valore formativo
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.VALUE) {
						$scope.hourValue = itemAttributes[k].attributeValue;
					}

					/* Recupero i tag - se non c'è il crossReferenceObject significa che non è un vero tag, quindi lo salto
					if(itemAttributes[k].crossReferenceObject){
						if(itemAttributes[k].attributeType == BgacademyApplicationData.constants.TECH_COMPETENCES){
							$scope.techCompetences.push(itemAttributes[k].crossReferenceObject.title);
						} else if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_COMPETENCES){
							$scope.softCompetences.push(itemAttributes[k].crossReferenceObject.title);
						}
					} */
				}
			}

			// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
			let hasScormRegistration: boolean = $scope.item.scormRegistration ? true : false;
			if (hasScormRegistration) {
				$scope.percentageItem = $scope.item.scormRegistration.score;
			}

			// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
			let hasItemRegistration: boolean = $scope.item && $scope.item.itemRegistration ? true : false;
			if (hasItemRegistration) {
				$scope.percentageItem = $scope.item.itemRegistration.currentPrecentage;
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			for (let d = 0; d < $scope.item.engagements.length; d++) {
				if ($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_OPEN) {
					// Oggetto aperto
					$scope.isItemOpen = true;
				}

				if (!hasItemRegistration && !hasScormRegistration && $scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_STARTED) {
					// Recupero la percentuale
					for (let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++) {
						if ($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE') {
							$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
							break;
						}
					}
				}

				if (!hasItemRegistration && !hasScormRegistration && $scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CONSUMED) {
					// Recupero la percentuale
					for (let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++) {
						if ($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE') {
							$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
							break;
						}
					}
				}
			}

			/* Se l'oggetto non è aperto, nè iniziato nè completato allora cerco i suggeriti
			if(!$scope.isItemOpen && !$scope.percentageItem){
				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for(let j = 0; j < $scope.item.itemVisibilities.length; j++){
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION && $scope.item.itemVisibilities[j].fromAdmin == true){
						$scope.suggestedFromAdmin = true;
					} else if($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION){
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for(let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.item.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if(groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.item.itemVisibilities[j].allowedUserId)){
							$scope.hasSuggested = true;

							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson = $scope.item.itemVisibilities[j].userObject;
						}
					}
				}
			}*/

		}


		// Controllo quale icona mostrare
		$scope.iconToShow = "EMPTY";
		$scope.iconToShowAlt = "EMPTY";
		// Versione standard
		if ($scope.card.objDisable) {
			$scope.iconToShow = "DISABLED";
		}
		/*
		else if ($scope.certifiedItem) {
			$scope.iconToShow = "CERTIFICATE";
		}
		*/
		else if ($scope.percentageItem) {
			$scope.iconToShow = "PERCENTAGE";
		}
		else if ($scope.isItemOpen) {
			$scope.iconToShow = "OPEN";
		}
		/*
		else if (scope.suggestedFromLibrary) {
			$scope.iconToShow = "LIBRARY";
		}
		*/
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		// Versione con forza persona
		if ($scope.card.objDisable) {
			$scope.iconToShowAlt = "DISABLED";
		}
		else if ($scope.forceToggleIndicator && $scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.forceToggleIndicator && $scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		/*
		else if ($scope.certifiedItem) {
			if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested){
			$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "CERTIFICATE";
			}
		}
		*/
		else if ($scope.percentageItem) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "PERCENTAGE";
			}
		}
		else if ($scope.isItemOpen) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "OPEN";
			}
		}
		/*
		else if (scope.suggestedFromLibrary) {
			if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested){
			$scope.iconToShowAlt = "PERSON";
			}
			else {
			$scope.iconToShowAlt = "LIBRARY";
			}
		}
		*/
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}

		// Porta alla pagina di dettaglio dell'oggetto selezionato
		$scope.goToCourseDetail = (courseId: string) => {
			// Se l'id passato alla funzione è diverso dall'id dell'oggetto Item significa che sto aprendo
			// la card di un elemento suggerito all'interno di un Item (es Learning Plan)
			$state.go("app.bgacademyApp.courseDetail");
		}
	}
});